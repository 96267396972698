import React from 'react';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaFacebook, FaTwitter, FaLinkedin, FaInstagram, FaClock, FaUserFriends, FaGift } from 'react-icons/fa';
import { MdLocationOn, MdHelpOutline, MdSupport } from 'react-icons/md';
import { motion } from 'framer-motion';

const ContactNueva = () => {
  return (
    <div className="bg-gray-100 py-16 px-4 relative">
      {/* WhatsApp Floating Banner */}
      <a 
        href="https://wa.me/654918257" 
        target="_blank" 
        rel="noopener noreferrer"
        className="fixed bottom-4 right-4 bg-green-500 text-white py-2 px-4 rounded-lg shadow-lg flex items-center space-x-2 z-50 hover:bg-green-600 transition duration-300"
      >
        <FaPhoneAlt className="text-xl" />
        <span>Chat en WhatsApp</span>
      </a>

      {/* SEO Meta Tags */}
      <head>
        <title>Contacto - Nuestra Empresa</title>
        <meta name="description" content="Contacta con nosotros para recibir asistencia personalizada. Ofrecemos soporte a través de diversos canales para tu comodidad." />
        <meta name="keywords" content="contacto, soporte, asistencia, empresa, información de contacto" />
        <meta property="og:title" content="Contacto - Nuestra Empresa" />
        <meta property="og:description" content="Contacta con nosotros para recibir asistencia personalizada. Ofrecemos soporte a través de diversos canales para tu comodidad." />
        <meta property="og:image" content="https://via.placeholder.com/1200x630" />
        <meta property="og:url" content="https://www.maytelas.com/contacto" />
      </head>

      {/* Header Section */}
      <header className="text-center mb-12">
        <h1 className="text-4xl font-extrabold text-gray-900 mb-4">¡Estamos Aquí Para Ayudarte!</h1>
        <p className="text-lg text-gray-600">Aquí encontrarás diferentes formas de contactarnos y resolver tus dudas de manera rápida y eficiente.</p>
      </header>

      {/* Contact Information */}
      <section className="container mx-auto md:flex md:justify-between md:space-x-8">
        {/* Contact Details */}
        <motion.div 
          className="bg-white p-8 rounded-lg shadow-lg mb-8 md:mb-0 md:w-1/3"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <h2 className="text-2xl font-bold mb-4">Información de Contacto</h2>
          <div className="flex items-center mb-4">
            <FaPhoneAlt className="text-blue-500 text-2xl mr-4" />
            <p className="text-lg text-gray-700">+34 654 918 257</p>
          </div>
          <div className="flex items-center mb-4">
            <FaEnvelope className="text-blue-500 text-2xl mr-4" />
            <p className="text-lg text-gray-700">Ayuda@maytelas.com</p>
          </div>
          <div className="flex items-center mb-4">
            <MdLocationOn className="text-blue-500 text-2xl mr-4" />
            <p className="text-lg text-gray-700">ESPAÑA</p>
          </div>
        </motion.div>

        {/* Social Media Links */}
        <motion.div 
          className="bg-white p-8 rounded-lg shadow-lg mb-8 md:mb-0 md:w-1/3"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <h2 className="text-2xl font-bold mb-4">Síguenos en Redes Sociales</h2>
          <div className="flex space-x-6">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
              <FaFacebook className="text-3xl" />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-600">
              <FaTwitter className="text-3xl" />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-blue-700 hover:text-blue-900">
              <FaLinkedin className="text-3xl" />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-pink-500 hover:text-pink-700">
              <FaInstagram className="text-3xl" />
            </a>
          </div>
        </motion.div>

        {/* Virtual Assistant Section */}
        <motion.div 
          className="bg-white p-8 rounded-lg shadow-lg md:w-1/3"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <h2 className="text-2xl font-bold mb-4">Asistente Virtual</h2>
          <div className="flex items-center mb-4">
            <MdHelpOutline className="text-green-500 text-2xl mr-4" />
            <p className="text-lg text-gray-700">Chat en vivo disponible 24/7 para resolver tus dudas y proporcionar asistencia inmediata.</p>
          </div>
          <div className="flex items-center mb-4">
            <FaClock className="text-yellow-500 text-2xl mr-4" />
            <p className="text-lg text-gray-700">Horario: Lunes a Viernes, 9:00 AM - 6:00 PM</p>
          </div>
        </motion.div>
      </section>

      {/* Map Section */}
      <section className="container mx-auto px-4 mt-12">
        <h2 className="text-3xl font-bold text-center mb-8">Nuestra Ubicación</h2>
        <div className="relative w-full h-80">
          <iframe 
            className="absolute inset-0 w-full h-full"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.5430285567404!2d-73.98542808459412!3d40.74881727932731!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259af1c13a2e3%3A0x30c58fa351a31dc6!2sEmpire%20State%20Building!5e0!3m2!1ses!2sus!4v1637945835960!5m2!1ses!2sus" 
            allowFullScreen="" 
            loading="lazy" 
            title="Ubicación de la Empresa"
          ></iframe>
        </div>
      </section>

      {/* Special Offers Section */}
      <section className="container mx-auto px-4 mt-12">
        <motion.div 
          className="bg-blue-600 text-white p-8 rounded-lg shadow-lg text-center"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          <h2 className="text-2xl font-bold mb-4">Ofertas Especiales</h2>
          <p className="mb-4">Aprovecha nuestras ofertas especiales por tiempo limitado:</p>
          <div className="flex justify-center space-x-6">
            <div className="bg-blue-700 p-6 rounded-lg">
              <FaGift className="text-3xl mb-2" />
              <p className="text-lg">Descuento del 20% en nuestro servicio de investigación.</p>
            </div>
            <div className="bg-blue-800 p-6 rounded-lg">
              <FaGift className="text-3xl mb-2" />
              <p className="text-lg">Consulta gratuita para nuevos clientes.</p>
            </div>
          </div>
        </motion.div>
      </section>

      {/* FAQs Section */}
      <section className="container mx-auto px-4 mt-12">
        <h2 className="text-3xl font-bold text-center mb-8">Preguntas Frecuentes</h2>
        <div className="bg-white p-8 rounded-lg shadow-lg">
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">¿Cómo puedo contactarlos?</h3>
            <p className="text-gray-700">Puedes contactarnos por teléfono, correo electrónico o a través de nuestras redes sociales. También puedes utilizar nuestro asistente virtual para ayuda inmediata.</p>
          </div>
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">¿Qué servicios ofrecen?</h3>
            <p className="text-gray-700">Ofrecemos investigación de infidelidades, seguimiento y vigilancia, análisis de comunicación y más. Consulta nuestra sección de servicios para más detalles.</p>
          </div>
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">¿Cuál es el horario de atención?</h3>
            <p className="text-gray-700">Nuestro horario es de lunes a viernes, de 9:00 AM a 6:00 PM.</p>
          </div>
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">¿Dónde están ubicados?</h3>
            <p className="text-gray-700">Estamos ubicados en 1234 Calle Principal, Ciudad, País. Consulta nuestro mapa para más detalles.</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactNueva;
