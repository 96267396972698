import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaSearch } from 'react-icons/fa';

const donations = [
  { id: 1, name: 'Donación para educación', description: 'Ayuda a financiar la educación de niños en comunidades rurales.', amount: '1', checkoutId: 'CHECKOUT_ID_1' },
  { id: 2, name: 'Donación para salud', description: 'Contribuye a la compra de suministros médicos esenciales.', amount: '1', checkoutId: 'CHECKOUT_ID_2' },
  { id: 3, name: 'Donación para alimentos', description: 'Proporciona alimentos para familias necesitadas.', amount: '1', checkoutId: 'CHECKOUT_ID_3' },
  { id: 4, name: 'Donación para agua potable', description: 'Ayuda a construir pozos de agua en comunidades sin acceso.', amount: '1', checkoutId: 'CHECKOUT_ID_4' },
  { id: 5, name: 'Donación para vivienda', description: 'Contribuye a la construcción de viviendas para familias necesitadas.', amount: '1', checkoutId: 'CHECKOUT_ID_5' },
  { id: 6, name: 'Donación para emergencias', description: 'Proporciona ayuda en situaciones de emergencia y desastres naturales.', amount: '1', checkoutId: 'CHECKOUT_ID_6' },
  { id: 7, name: 'Donación para refugiados', description: 'Apoya a los refugiados con necesidades básicas y servicios esenciales.', amount: '1', checkoutId: 'CHECKOUT_ID_7' },
  { id: 8, name: 'Donación para animales', description: 'Ayuda a proteger y cuidar animales en peligro de extinción.', amount: '1', checkoutId: 'CHECKOUT_ID_8' },
  { id: 9, name: 'Donación para medio ambiente', description: 'Contribuye a la conservación y protección del medio ambiente.', amount: '1', checkoutId: 'CHECKOUT_ID_9' },
  { id: 10, name: 'Donación para investigación médica', description: 'Apoya la investigación para encontrar curas para enfermedades graves.', amount: '1', checkoutId: 'CHECKOUT_ID_10' },
  { id: 11, name: 'Donación para arte y cultura', description: 'Fomenta el desarrollo de proyectos artísticos y culturales.', amount: '1', checkoutId: 'CHECKOUT_ID_11' },
  { id: 12, name: 'Donación para deportes', description: 'Apoya a jóvenes talentos deportivos en su desarrollo.', amount: '1', checkoutId: 'CHECKOUT_ID_12' },
  { id: 13, name: 'Donación para tecnología', description: 'Impulsa proyectos tecnológicos innovadores y educativos.', amount: '1', checkoutId: 'CHECKOUT_ID_13' },
];

const DonationsList = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredDonations = donations.filter(donation =>
    donation.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <section className="py-20 bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-8">Donaciones Disponibles</h2>
        <div className="flex justify-center mb-8">
          <div className="relative w-full md:w-1/2 lg:w-1/3">
            <input
              type="text"
              placeholder="Buscar donaciones..."
              className="p-2 border border-gray-300 rounded w-full"
              onChange={e => setSearchTerm(e.target.value)}
            />
            <FaSearch className="absolute top-2 right-2 text-gray-500" />
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {filteredDonations.map((donation) => (
            <motion.div
              key={donation.id}
              className="p-4 text-center bg-white shadow-md rounded-lg"
              whileHover={{ scale: 1.05 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <h3 className="text-xl font-semibold mb-2">{donation.name}</h3>
              <p className="text-sm mb-4">{donation.description}</p>
              <p className="text-lg font-bold">€{donation.amount}</p>
              <a
                href={`https://commerce.coinbase.com/checkout/${donation.checkoutId}`}
                target="_blank"
                rel="noopener noreferrer"
                className="block w-full bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded mt-4"
              >
                Realizar Donación
              </a>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default DonationsList;
