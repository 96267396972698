import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Función para alternar la visibilidad del menú desplegable
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  return (
    <nav className="bg-blue-600 text-white p-4">
      <div className="max-w-6xl mx-auto flex justify-between items-center">
        <Link to="/" className="text-2xl font-bold">Donativos</Link>

        {/* Dropdown container */}
        <div className="relative">
          {/* Botón principal */}
          <button
            onClick={toggleDropdown}
            className="flex items-center focus:outline-none hover:text-gray-300"
          >
            Menú <svg className="h-4 w-4 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M10 12a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
              <path fillRule="evenodd" d="M10 4a2 2 0 100 4 2 2 0 000-4z" clipRule="evenodd" />
              <path fillRule="evenodd" d="M10 20a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
            </svg>
          </button>

          {/* Dropdown */}
          {isOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-2 z-10">
              <NavLink
                to="/"
                className="block px-4 py-2 text-gray-800 hover:bg-blue-100"
                activeClassName="text-gray-300"
                exact
                onClick={closeDropdown} // Cerrar el menú al hacer clic en un enlace
              >
                Inicio
              </NavLink>
              <NavLink
                to="/about"
                className="block px-4 py-2 text-gray-800 hover:bg-blue-100"
                activeClassName="text-gray-300"
                onClick={closeDropdown} // Cerrar el menú al hacer clic en un enlace
              >
                Sobre Nosotros
              </NavLink>
              <NavLink
                to="/projects"
                className="block px-4 py-2 text-gray-800 hover:bg-blue-100"
                activeClassName="text-gray-300"
                onClick={closeDropdown} // Cerrar el menú al hacer clic en un enlace
              >
                Proyectos
              </NavLink>
              <NavLink
                to="/donations"
                className="block px-4 py-2 text-gray-800 hover:bg-blue-100"
                activeClassName="text-gray-300"
                onClick={closeDropdown} // Cerrar el menú al hacer clic en un enlace
              >
                Donaciones
              </NavLink>
              <NavLink
                to="/contact"
                className="block px-4 py-2 text-gray-800 hover:bg-blue-100"
                activeClassName="text-gray-300"
                onClick={closeDropdown} // Cerrar el menú al hacer clic en un enlace
              >
                Contacto
              </NavLink>
              <NavLink
                to="/faq"
                className="block px-4 py-2 text-gray-800 hover:bg-blue-100"
                activeClassName="text-gray-300"
                onClick={closeDropdown} // Cerrar el menú al hacer clic en un enlace
              >
                FAQ
              </NavLink>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
