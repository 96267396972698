import React, { useState } from 'react';
import { FaUser, FaUsers, FaUserShield, FaUserMd, FaUserTie, FaSearch } from 'react-icons/fa';

const teamMembers = [
  {
    name: "Juan Pérez",
    role: "Investigador Jefe",
    icon: <FaUser className="text-4xl text-gray-600" />,
    bio: "Juan Pérez es un investigador con más de 10 años de experiencia en el sector. Su experiencia incluye investigaciones de campo, análisis de datos y publicación de estudios especializados.",
    additionalInfo: "Juan ha liderado numerosos proyectos exitosos y es conocido por su enfoque innovador en la investigación."
  },
  {
    name: "María Gómez",
    role: "Analista de Comunicaciones",
    icon: <FaUsers className="text-4xl text-gray-600" />,
    bio: "María Gómez es experta en comunicaciones y manejo de crisis. Su enfoque está en la estrategia y el análisis para mejorar la comunicación interna y externa de la organización.",
    additionalInfo: "María ha implementado estrategias de comunicación efectivas que han mejorado significativamente el perfil de la empresa en el mercado."
  },
  {
    name: "Carlos López",
    role: "Especialista en Vigilancia",
    icon: <FaUserShield className="text-4xl text-gray-600" />,
    bio: "Carlos López es un especialista en técnicas avanzadas de vigilancia y análisis de datos. Tiene una sólida trayectoria en la implementación de sistemas de seguridad y monitoreo.",
    additionalInfo: "Carlos ha desarrollado tecnologías de vigilancia de última generación que han aumentado la eficacia de los sistemas de seguridad."
  },
  {
    name: "Ana Martínez",
    role: "Psicóloga",
    icon: <FaUserMd className="text-4xl text-gray-600" />,
    bio: "Ana Martínez es una psicóloga con amplia experiencia en terapia clínica y análisis psicológico. Se especializa en la evaluación y tratamiento de trastornos emocionales y psicológicos.",
    additionalInfo: "Ana ha trabajado con diversos pacientes, ayudando a mejorar su bienestar mental y emocional mediante enfoques terapéuticos personalizados."
  },
  {
    name: "Roberto Fernández",
    role: "Detective Privado",
    icon: <FaSearch className="text-4xl text-gray-600" />,
    bio: "Roberto Fernández es un detective privado con habilidades excepcionales en investigación de casos complejos y resolución de crímenes. Su trabajo incluye la recopilación de pruebas y análisis de información.",
    additionalInfo: "Roberto ha resuelto numerosos casos que requerían un enfoque minucioso y detallado, aplicando técnicas avanzadas de investigación."
  },
  {
    name: "Laura Rodríguez",
    role: "Psicóloga Forense",
    icon: <FaUserMd className="text-4xl text-gray-600" />,
    bio: "Laura Rodríguez es una psicóloga forense con experiencia en la evaluación de testimonios y comportamientos en el contexto judicial. Su trabajo ayuda en la toma de decisiones legales y en la interpretación de pruebas psicológicas.",
    additionalInfo: "Laura ha colaborado con el sistema judicial para ofrecer evaluaciones psicológicas precisas y fundamentadas en casos legales."
  },
  {
    name: "Fernando Gómez",
    role: "Investigador Criminal",
    icon: <FaUserShield className="text-4xl text-gray-600" />,
    bio: "Fernando Gómez es un investigador criminal con experiencia en la investigación de delitos graves y delitos organizados. Se especializa en el análisis de escenas del crimen y en la recopilación de pruebas.",
    additionalInfo: "Fernando ha trabajado en casos de alto perfil, aplicando su conocimiento para resolver crímenes complejos y obtener justicia."
  },
  {
    name: "Elena Torres",
    role: "Especialista en Inteligencia",
    icon: <FaUserTie className="text-4xl text-gray-600" />,
    bio: "Elena Torres es una especialista en inteligencia con experiencia en análisis de datos y elaboración de informes de inteligencia. Su trabajo es fundamental para la seguridad y la toma de decisiones estratégicas.",
    additionalInfo: "Elena ha desarrollado y gestionado proyectos de inteligencia que han proporcionado información valiosa para la protección y seguridad de la organización."
  },
];

const Team = () => {
  const [selectedMember, setSelectedMember] = useState(null);

  const handleMemberClick = (member) => {
    setSelectedMember(member);
  };

  const handleCloseModal = () => {
    setSelectedMember(null);
  };

  return (
    <section id="team" className="py-12 bg-gray-100 text-gray-800">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center text-gray-900">Conoce a Nuestro Equipo</h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-12">
          {teamMembers.map((member, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105 hover:shadow-xl duration-300 text-center p-6 cursor-pointer"
              onClick={() => handleMemberClick(member)}
            >
              <div className="mb-4 flex justify-center">
                {member.icon}
              </div>
              <h3 className="text-xl font-semibold mb-2 text-gray-800">{member.name}</h3>
              <p className="text-md text-gray-600 mb-4">{member.role}</p>
            </div>
          ))}
        </div>

        {selectedMember && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50" onClick={handleCloseModal}>
            <div className="bg-white rounded-lg shadow-lg max-w-lg w-full p-8 relative" onClick={(e) => e.stopPropagation()}>
              <button className="absolute top-2 right-2 text-gray-600 hover:text-gray-900" onClick={handleCloseModal}>
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
              <div className="flex justify-center mb-6">
                {selectedMember.icon}
              </div>
              <h3 className="text-2xl font-semibold mb-2 text-gray-800">{selectedMember.name}</h3>
              <p className="text-lg text-gray-600 mb-4">{selectedMember.role}</p>
              <p className="text-gray-700 mb-4">{selectedMember.bio}</p>
              <p className="text-gray-500">{selectedMember.additionalInfo}</p>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Team;
