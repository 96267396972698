import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-4">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row justify-between items-center">
        <div className="mb-4 md:mb-0">
          <Link to="/" className="text-2xl font-bold">MAYTELAS</Link>
        </div>
        <div className="flex flex-wrap justify-center md:justify-end space-x-4">
          <Link to="/" className="hover:text-gray-400">Inicio</Link>
          <Link to="/Acerca" className="hover:text-gray-400">Sobre Nosotros</Link>
          <Link to="/Servicios" className="hover:text-gray-400">Servicios</Link>
          <Link to="/contacto" className="hover:text-gray-400">Contacto</Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
