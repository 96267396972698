import React from 'react';
import { FaPeopleArrows, FaBriefcase, FaPhoneAlt } from 'react-icons/fa';

const Acerca = () => {
  return (
    <div className="bg-gray-50">
      {/* Hero Section */}
      <header className="bg-gradient-to-r from-blue-500 to-teal-600 text-white py-16 text-center">
        <h1 className="text-5xl font-extrabold">Sobre Nosotros</h1>
        <p className="mt-4 text-xl">Conoce más sobre nuestro equipo y nuestra misión.</p>
      </header>

      {/* Misión y Visión Section */}
      <main className="container mx-auto px-4 py-16">
        <section className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-8">Nuestra Misión y Visión</h2>
          <p className="text-lg max-w-2xl mx-auto">
            En [Nombre de la Empresa], nuestra misión es proporcionar servicios de investigación privados con el mayor nivel de profesionalismo y discreción. Nos dedicamos a ofrecer soluciones efectivas para resolver casos de infidelidad, seguimientos y análisis de comunicaciones digitales. Nuestra visión es ser líderes en el sector, reconocidos por nuestra ética, transparencia y resultados confiables.
          </p>
        </section>

        {/* Nuestro Equipo Section */}
        <section className="bg-white py-16 mb-16">
          <h2 className="text-4xl font-bold mb-8 text-center">Conoce a Nuestro Equipo</h2>
          <div className="container mx-auto px-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg text-center">
              <img src="https://via.placeholder.com/150" alt="John Doe" className="w-32 h-32 mx-auto rounded-full mb-4" />
              <h3 className="text-2xl font-semibold mb-2">Juan Pérez</h3>
              <p className="text-gray-600">Director de Operaciones</p>
              <p className="mt-4">Con más de 15 años de experiencia en el sector, John lidera nuestro equipo con un enfoque en la excelencia y la atención al detalle.</p>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg text-center">
              <img src="https://via.placeholder.com/150" alt="Jane Smith" className="w-32 h-32 mx-auto rounded-full mb-4" />
              <h3 className="text-2xl font-semibold mb-2">María Gómez</h3>
              <p className="text-gray-600">Investigadora Principal</p>
              <p className="mt-4">Jane aporta una gran experiencia en investigaciones y análisis, especializada en la recolección de pruebas y el seguimiento de casos complejos.</p>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg text-center">
              <img src="https://via.placeholder.com/150" alt="Michael Brown" className="w-32 h-32 mx-auto rounded-full mb-4" />
              <h3 className="text-2xl font-semibold mb-2">Carlos López</h3>
              <p className="text-gray-600">Analista de Comunicaciones</p>
              <p className="mt-4">Michael se especializa en el análisis de datos digitales y comunicaciones, asegurando la precisión en la interpretación de la evidencia recolectada.</p>
            </div>
          </div>
        </section>

        {/* Valores de la Empresa Section */}
        <section className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-8">Nuestros Valores</h2>
          <div className="flex flex-col lg:flex-row lg:justify-center lg:space-x-8">
            <div className="bg-white p-6 rounded-lg shadow-lg mb-8 lg:mb-0 flex items-center justify-center flex-col">
              <FaPeopleArrows className="text-blue-600 text-4xl mb-4" />
              <h3 className="text-2xl font-semibold mb-2">Integridad</h3>
              <p className="text-gray-600">Actuamos con honestidad y ética en todas nuestras interacciones y servicios.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg mb-8 lg:mb-0 flex items-center justify-center flex-col">
              <FaBriefcase className="text-green-600 text-4xl mb-4" />
              <h3 className="text-2xl font-semibold mb-2">Profesionalismo</h3>
              <p className="text-gray-600">Ofrecemos servicios con el más alto nivel de competencia y respeto hacia nuestros clientes.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg flex items-center justify-center flex-col">
              <FaPhoneAlt className="text-purple-600 text-4xl mb-4" />
              <h3 className="text-2xl font-semibold mb-2">Compromiso</h3>
              <p className="text-gray-600">Estamos dedicados a resolver los casos de nuestros clientes con el mayor cuidado y eficacia.</p>
            </div>
          </div>
        </section>

        {/* Contact Section */}
        <section className="bg-gray-100 py-16 text-center">
          <h2 className="text-4xl font-bold mb-8">Contáctanos</h2>
          <p className="text-lg max-w-2xl mx-auto mb-8">Si tienes alguna pregunta o deseas saber más sobre nuestros servicios, no dudes en ponerte en contacto con nosotros. Estamos aquí para ayudarte.</p>
          <a
            href="mailto:ayuda@maytelas.com"
            className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded transition-colors duration-300"
          >
            Enviar un Correo
          </a>
        </section>
      </main>
    </div>
  );
};

export default Acerca;
