import React from 'react';
import { FaUserSecret, FaSearch, FaMobileAlt } from 'react-icons/fa';

const Services = () => {
  return (
    <section id="services" className="py-12 bg-white text-gray-800">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8 text-center">Nuestros Servicios</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="p-6 bg-gray-100 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
            <div className="flex items-center justify-center mb-4">
              <FaUserSecret className="text-4xl text-blue-600" />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-center">Investigación de Infidelidades</h3>
            <p className="text-center">Recolección de pruebas discretas y confidenciales para casos de sospecha de infidelidad.</p>
          </div>
          <div className="p-6 bg-gray-100 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
            <div className="flex items-center justify-center mb-4">
              <FaSearch className="text-4xl text-green-600" />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-center">Seguimientos y Vigilancias</h3>
            <p className="text-center">Monitoreo profesional de personas y actividades para obtener información relevante.</p>
          </div>
          <div className="p-6 bg-gray-100 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
            <div className="flex items-center justify-center mb-4">
              <FaMobileAlt className="text-4xl text-red-600" />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-center">Análisis de Comunicación</h3>
            <p className="text-center">Revisión de comunicaciones digitales para descubrir evidencias de conductas sospechosas.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
