import React from 'react';

const InfoPage = () => {
  return (
    <section id="info-page" className="py-12 bg-gray-100 text-gray-800">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold mb-12 text-center">Información Importante</h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Cómo Detectar Señales de Infidelidad */}
          <div className="bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105 hover:shadow-xl duration-300">
            <h2 className="text-3xl font-semibold mb-4">Cómo Detectar Señales de Infidelidad</h2>
            <p className="mb-4">La infidelidad puede ser una experiencia dolorosa y desafiante. Aquí te presentamos algunos signos y señales que podrían indicar problemas en la relación:</p>
            <ul className="list-disc pl-5 mb-4">
              <li><strong>Cambio en el comportamiento:</strong> Si notas un cambio abrupto en el comportamiento de tu pareja, podría ser un indicio.</li>
              <li><strong>Secrecía con dispositivos electrónicos:</strong> La ocultación de teléfonos y computadoras puede ser una señal.</li>
              <li><strong>Menos tiempo en casa:</strong> Si tu pareja pasa menos tiempo en casa sin una razón clara, es un signo a considerar.</li>
              <li><strong>Actitudes defensivas:</strong> Si tu pareja se vuelve defensiva o evasiva cuando se le pregunta sobre su día, esto puede ser preocupante.</li>
              <li><strong>Variaciones en el interés sexual:</strong> Un cambio en el interés o la intimidad sexual también puede ser un indicador.</li>
            </ul>
            <p>Recuerda que estos signos no garantizan una infidelidad. La comunicación abierta y honesta es clave para abordar cualquier problema en la relación.</p>
          </div>

          {/* Casos de Estudio: Lecciones Aprendidas */}
          <div className="bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105 hover:shadow-xl duration-300">
            <h2 className="text-3xl font-semibold mb-4">Casos de Estudio: Lecciones Aprendidas</h2>
            <p className="mb-4">En esta sección, exploramos varios casos de infidelidad y las lecciones aprendidas de ellos. Estos estudios de caso proporcionan perspectivas valiosas:</p>
            <ul className="list-disc pl-5 mb-4">
              <li><strong>Estudio de Caso 1:</strong> Lecciones aprendidas de un caso donde la comunicación falló.</li>
              <li><strong>Estudio de Caso 2:</strong> Estrategias efectivas para manejar la infidelidad descubierta a través de investigaciones.</li>
              <li><strong>Estudio de Caso 3:</strong> Cómo se resolvieron los conflictos y qué se puede aprender para futuras relaciones.</li>
            </ul>
            <p>Estos casos ofrecen una comprensión profunda de cómo manejar situaciones similares en tu propia vida.</p>
          </div>

          {/* Consejos para Mantener una Relación Saludable */}
          <div className="bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105 hover:shadow-xl duration-300">
            <h2 className="text-3xl font-semibold mb-4">Consejos para Mantener una Relación Saludable</h2>
            <p className="mb-4">Mantener una relación saludable requiere esfuerzo mutuo. Aquí hay algunos consejos para fortalecer tu relación:</p>
            <ul className="list-disc pl-5 mb-4">
              <li><strong>Comunicación abierta:</strong> Habla sobre tus sentimientos y expectativas de manera clara y sincera.</li>
              <li><strong>Tiempo de calidad:</strong> Dedica tiempo para actividades juntos que ambos disfruten.</li>
              <li><strong>Resolución de conflictos:</strong> Aborda los problemas con una actitud constructiva y busca soluciones juntos.</li>
              <li><strong>Empatía y comprensión:</strong> Practica la empatía para comprender mejor las perspectivas de tu pareja.</li>
              <li><strong>Mostrar aprecio:</strong> Expresa gratitud y aprecio regularmente para mantener una conexión emocional fuerte.</li>
            </ul>
            <p>Estos consejos pueden ayudarte a construir una relación más fuerte y duradera.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InfoPage;
