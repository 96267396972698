import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';

const BannerMain = () => {
  const navigate = useNavigate();

  const goToContact = () => {
    navigate('/contacto');
  };

  return (
    <section className="relative bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-300 text-white py-24">
      <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1542524224-132d67105b53')] bg-cover bg-center opacity-30"></div>
      <div className="relative container mx-auto px-4 text-center">
        <h1 className="text-5xl md:text-6xl font-extrabold mb-4 tracking-tight leading-tight">
          Descubre <span className="text-yellow-300">La Verdad</span> con Nuestro Servicio
        </h1>
        <p className="text-lg md:text-2xl mb-8">
          Ofrecemos investigación confidencial con el más alto nivel de profesionalismo. <br />
          Tu paz mental es nuestra prioridad.
        </p>
        <button
          onClick={goToContact}
          className="inline-flex items-center bg-yellow-400 hover:bg-yellow-500 text-black font-semibold py-3 px-6 rounded-full shadow-lg transition-colors duration-300 transform hover:scale-105"
        >
          Contáctanos
          <FaArrowRight className="ml-2" />
        </button>
      </div>
    </section>
  );
};

export default BannerMain;
