import React, { useState } from 'react';
import { FaHandsHelping, FaMoneyBillAlt, FaUtensils, FaTshirt, FaHeart, FaCheckCircle } from 'react-icons/fa';
import { motion } from 'framer-motion';

const DonationSurvey = () => {
  const [surveyData, setSurveyData] = useState({
    problem: '',
    donationAmount: 0,
  });

  const [donationTotal, setDonationTotal] = useState(0);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleChange = (e) => {
    setSurveyData({
      ...surveyData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDonationTotal(donationTotal + parseInt(surveyData.donationAmount));
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 3000);
    setSurveyData({
      problem: '',
      donationAmount: 0,
    });
  };

  const handleSmartFunction1 = () => {
    // Implementa la función inteligente 1
    console.log('Función Inteligente 1');
  };

  const handleSmartFunction2 = () => {
    // Implementa la función inteligente 2
    console.log('Función Inteligente 2');
  };

  const handleSmartFunction3 = () => {
    // Implementa la función inteligente 3
    console.log('Función Inteligente 3');
  };

  // Repite el patrón para las otras funciones inteligentes

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-4xl font-bold mb-6 text-center">Formulario de Donación y Encuesta</h1>
      <form onSubmit={handleSubmit} className="max-w-md mx-auto">
        <div className="mb-6">
          <label htmlFor="problem" className="block mb-2 text-lg font-bold">
            Problema:
          </label>
          <input
            type="text"
            id="problem"
            name="problem"
            value={surveyData.problem}
            onChange={handleChange}
            className="border border-gray-300 p-2 w-full rounded"
            required
          />
        </div>
        <div className="mb-6">
          <label htmlFor="donationAmount" className="block mb-2 text-lg font-bold">
            Cantidad de Donación:
          </label>
          <input
            type="number"
            id="donationAmount"
            name="donationAmount"
            value={surveyData.donationAmount}
            onChange={handleChange}
            className="border border-gray-300 p-2 w-full rounded"
            required
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Enviar
        </button>
      </form>
      {showSuccessMessage && (
        <div className="text-center mt-4">
          <p className="text-green-500 font-bold">
            <FaCheckCircle className="inline-block mr-2" />
            ¡Gracias por tu donación!
          </p>
        </div>
      )}
      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4">Total de Donaciones: ${donationTotal}</h2>
        <motion.button
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-4"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={handleSmartFunction1}
        >
          Función Inteligente 1
        </motion.button>
        <motion.button
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-4"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={handleSmartFunction2}
        >
          Función Inteligente 2
        </motion.button>
        <motion.button
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={handleSmartFunction3}
        >
          Función Inteligente 3
        </motion.button>
        {/* Repite el patrón para las otras funciones inteligentes */}
      </div>
    </div>
  );
};

const DonationPage = () => {
  const handleParticipate = (problem) => {
    // Acción correspondiente al botón "Participar"
    console.log(`Participando en el problema: ${problem}`);
  };

  const handleDonate = (project) => {
    // Acción correspondiente al botón "Donar"
    console.log(`Donando al proyecto: ${project}`);
  };

  const handleReadMore = (story) => {
    // Acción correspondiente al botón "Leer más"
    console.log(`Leyendo más sobre la historia: ${story}`);
  };

  const handleShareFacebook = () => {
    // Acción correspondiente al botón "Compartir en Facebook"
    console.log('Compartiendo en Facebook');
  };

  const handleShareTwitter = () => {
    // Acción correspondiente al botón "Compartir en Twitter"
    console.log('Compartiendo en Twitter');
  };

  const handleViewAchievements = () => {
    // Acción correspondiente al botón "Ver Logros"
    console.log('Viendo logros');
  };

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-5xl font-bold mb-8 text-center">Página de Donaciones</h1>
      <div className="mb-8">
        <h2 className="text-3xl font-bold mb-4">Seguridad y Protección</h2>
        <p className="text-gray-800">
          Nos tomamos la seguridad de tus donaciones muy en serio. Todas las transacciones se procesan a través de pasarelas de pago seguras que cifran tu información y protegen tus datos sensibles.
        </p>
      </div>
      <div className="mb-8">
        <h2 className="text-3xl font-bold mb-4">Transparencia</h2>
        <p className="text-gray-800">
          Estamos comprometidos con la transparencia en nuestro proceso de donaciones. Proporcionamos actualizaciones y reportes periódicos sobre cómo se utilizan tus donaciones para abordar los problemas sociales, animales o medioambientales que te importan.
        </p>
      </div>
      <div className="mb-8">
        <h2 className="text-3xl font-bold mb-4">Problemas Sociales</h2>
        <div className="grid grid-cols-2 gap-8">
          <motion.div
            className="bg-white p-8 rounded shadow-md flex flex-col items-center"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <FaHandsHelping className="text-6xl mb-4 text-blue-500" />
            <h3 className="text-2xl font-bold mb-4">Reinserción para personas sin hogar</h3>
            <p className="text-gray-800 mb-4">Ayuda a personas sin hogar a darles una segunda oportunidad.</p>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => handleParticipate('Problema 1')}
            >
              Participar
            </button>
          </motion.div>
          <motion.div
            className="bg-white p-8 rounded shadow-md flex flex-col items-center"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <FaMoneyBillAlt className="text-6xl mb-4 text-green-500" />
            <h3 className="text-2xl font-bold mb-4">Guerra de Ucrania</h3>
            <p className="text-gray-800 mb-4">Envio de alimento, ropa y material sanitario.</p>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => handleParticipate('Problema 2')}
            >
              Participar
            </button>
          </motion.div>
          <motion.div
            className="bg-white p-8 rounded shadow-md flex flex-col items-center"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <FaUtensils className="text-6xl mb-4 text-red-500" />
            <h3 className="text-2xl font-bold mb-4">Envío de Alimentos</h3>
            <p className="text-gray-800 mb-4">Ayuda a proporcionar alimentos a comunidades necesitadas.</p>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => handleParticipate('Envío de Alimentos')}
            >
              Participar
            </button>
          </motion.div>
          <motion.div
            className="bg-white p-8 rounded shadow-md flex flex-col items-center"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <FaTshirt className="text-6xl mb-4 text-purple-500" />
            <h3 className="text-2xl font-bold mb-4">Compra de Ropa</h3>
            <p className="text-gray-800 mb-4">Contribuye a comprar ropa para personas sin hogar.</p>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => handleParticipate('Compra de Ropa')}
            >
              Participar
            </button>
          </motion.div>
          {/* Agrega más encuestas aquí */}
        </div>
      </div>
      <div className="mt-8">
        <h2 className="text-3xl font-bold mb-4">Cómo funcionan los donativos</h2>
        <p className="text-gray-800">
          Los donativos que recibimos se utilizan para financiar proyectos y programas relacionados con los problemas sociales, animales o medioambientales que se mencionan en las encuestas. Garantizamos la transparencia en el uso de los fondos y proporcionamos informes regulares sobre cómo se están utilizando las donaciones para generar un impacto positivo en las comunidades afectadas. Agradecemos tu generosidad y confianza en nuestro trabajo.
        </p>
      </div>
      {/* Agrega las 50 líneas de información adicional */}
      <div className="mt-8">
        <h2 className="text-3xl font-bold mb-4">Galería de Proyectos</h2>
        <div className="grid grid-cols-2 gap-8">
          <div className="bg-white p-8 rounded shadow-md">
            <h3 className="text-2xl font-bold mb-4">Proyecto 1</h3>
            <p className="text-gray-800 mb-4">Descripción del proyecto 1</p>
            <div className="bg-gray-200 h-4 rounded mb-4">
              <div className="bg-blue-500 h-full rounded" style={{ width: '50%' }}></div>
            </div>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => handleDonate('Proyecto 1')}
            >
              Donar
            </button>
          </div>
          <div className="bg-white p-8 rounded shadow-md">
            <h3 className="text-2xl font-bold mb-4">Proyecto 2</h3>
            <p className="text-gray-800 mb-4">Descripción del proyecto 2</p>
            <div className="bg-gray-200 h-4 rounded mb-4">
              <div className="bg-blue-500 h-full rounded" style={{ width: '25%' }}></div>
            </div>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => handleDonate('Proyecto 2')}
            >
              Donar
            </button>
          </div>
          {/* Agrega más proyectos aquí */}
        </div>
      </div>
      <div className="mt-8">
        <h2 className="text-3xl font-bold mb-4">Historias de Impacto</h2>
        <div className="grid grid-cols-2 gap-8">
          <div className="bg-white p-8 rounded shadow-md">
            <h3 className="text-2xl font-bold mb-4">Historia 1</h3>
            <p className="text-gray-800 mb-4">Descripción de la historia 1</p>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => handleReadMore('Historia 1')}
            >
              Leer más
            </button>
          </div>
          <div className="bg-white p-8 rounded shadow-md">
            <h3 className="text-2xl font-bold mb-4">Historia 2</h3>
            <p className="text-gray-800 mb-4">Descripción de la historia 2</p>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => handleReadMore('Historia 2')}
            >
              Leer más
            </button>
          </div>
          {/* Agrega más historias aquí */}
        </div>
      </div>
      <div className="mt-8">
        <h2 className="text-3xl font-bold mb-4">Integración de Redes Sociales</h2>
        <div className="flex items-center">
          <button
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4"
            onClick={handleShareFacebook}
          >
            Compartir en Facebook
          </button>
          <button
            className="bg-blue-400 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded"
            onClick={handleShareTwitter}
          >
            Compartir en Twitter
          </button>
        </div>
      </div>
      <div className="mt-8">
        <h2 className="text-3xl font-bold mb-4">Gamificación</h2>
        <p className="text-gray-800 mb-4">¡Gana puntos por tus donaciones! Cada donación te acerca a desbloquear nuevos logros y premios especiales.</p>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={handleViewAchievements}
        >
          Ver Logros
        </button>
      </div>
    </div>
  );
};

export default DonationPage;
