import React, { useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

// Componente para mostrar tarjetas de precios
function PricingCard({ title, price, features }) {
  return (
    <div className="max-w-sm rounded-lg overflow-hidden shadow-lg my-4 bg-white transform transition-transform hover:scale-105 duration-300 ease-in-out">
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{title}</div>
        <p className="text-gray-700 text-base">
          <span className="text-3xl font-bold">{price}</span> / mes
        </p>
      </div>
      <ul className="px-6 pt-4 pb-2 space-y-2">
        {features.map((feature, index) => (
          <li key={index} className="text-gray-700 text-base flex items-center">
            <InformationCircleIcon className="w-5 h-5 text-blue-500 mr-2" />
            {feature}
          </li>
        ))}
      </ul>
      <div className="px-6 pt-4 pb-2">
        <a
          href="https://wa.me/654918257"  // Reemplaza con tu número de WhatsApp
          target="_blank"
          rel="noopener noreferrer"
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition-colors duration-300 ease-in-out text-center block"
        >
          Contratar
        </a>
      </div>
    </div>
  );
}
 
// Componente para mostrar el modal
function Modal({ onClose }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 transition-opacity duration-300 ease-in-out">
      <div className="bg-white rounded-lg overflow-hidden shadow-xl max-w-lg w-full transform transition-transform scale-100 hover:scale-105 duration-300 ease-in-out">
        <div className="p-4">
          <h2 className="text-2xl font-bold mb-4">Más Información</h2>
          <p className="mb-4">Aquí puedes proporcionar más detalles sobre tus servicios.</p>
          <button
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition-colors duration-300 ease-in-out"
            onClick={onClose}
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
}

// Componente principal para la página de precios
function Prices() {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  return (
    <div>
      <header className="bg-gray-800 text-white py-8">
        <div className="container mx-auto text-center">
          <h1 className="text-4xl font-bold">Precios de Servicios de Investigación Privada</h1>
          <p className="text-xl mt-2">Ofrecemos planes flexibles para adaptarnos a tus necesidades específicas.</p>
        </div>
      </header>

      <main className="container mx-auto py-8">
        <section className="mb-12">
          <h2 className="text-3xl font-bold text-center mb-6">Descripción del Servicio</h2>
          <p className="text-center">Proporcionamos servicios de investigación privada tanto para particulares como para empresas, asegurando la máxima confidencialidad y resultados precisos.</p>
        </section>

        <section className="mb-12">
          <h2 className="text-3xl font-bold text-center mb-6">Características y Beneficios</h2>
          <div className="flex flex-wrap justify-around gap-6">
            <div className="flex flex-col md:flex-row gap-6 md:gap-12">
              {/* Características */}
              <div className="max-w-md rounded-lg overflow-hidden shadow-lg bg-white p-6">
                <h3 className="text-2xl font-bold mb-4">Características</h3>
                <ul className="list-disc list-inside space-y-2">
                  <li className="text-gray-700 flex items-center">
                    <InformationCircleIcon className="w-5 h-5 text-blue-500 mr-2" />
                    Investigación exhaustiva
                  </li>
                  <li className="text-gray-700 flex items-center">
                    <InformationCircleIcon className="w-5 h-5 text-blue-500 mr-2" />
                    Informes detallados
                  </li>
                  <li className="text-gray-700 flex items-center">
                    <InformationCircleIcon className="w-5 h-5 text-blue-500 mr-2" />
                    Vigilancia continua
                  </li>
                  <li className="text-gray-700 flex items-center">
                    <InformationCircleIcon className="w-5 h-5 text-blue-500 mr-2" />
                    Análisis forense digital
                  </li>
                  <li className="text-gray-700 flex items-center">
                    <InformationCircleIcon className="w-5 h-5 text-blue-500 mr-2" />
                    Consultoría personalizada
                  </li>
                </ul>
              </div>

              {/* Beneficios */}
              <div className="max-w-md rounded-lg overflow-hidden shadow-lg bg-white p-6">
                <h3 className="text-2xl font-bold mb-4">Beneficios</h3>
                <ul className="list-disc list-inside space-y-2">
                  <li className="text-gray-700 flex items-center">
                    <InformationCircleIcon className="w-5 h-5 text-green-500 mr-2" />
                    Confidencialidad garantizada
                  </li>
                  <li className="text-gray-700 flex items-center">
                    <InformationCircleIcon className="w-5 h-5 text-green-500 mr-2" />
                    Resultados rápidos y precisos
                  </li>
                  <li className="text-gray-700 flex items-center">
                    <InformationCircleIcon className="w-5 h-5 text-green-500 mr-2" />
                    Equipo profesional y experimentado
                  </li>
                  <li className="text-gray-700 flex items-center">
                    <InformationCircleIcon className="w-5 h-5 text-green-500 mr-2" />
                    Soporte 24/7
                  </li>
                  <li className="text-gray-700 flex items-center">
                    <InformationCircleIcon className="w-5 h-5 text-green-500 mr-2" />
                    Soluciones personalizadas
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="mb-12">
          <h2 className="text-3xl font-bold text-center mb-6">Planes y Precios</h2>
          <div className="flex flex-col md:flex-row justify-around items-center">
            <div className="w-full md:w-1/2 lg:w-1/3 px-4">
              <h3 className="text-2xl font-bold text-center mb-4">Para Particulares</h3>
              <PricingCard
                title="Básico"
                price="€500"
                features={[
                  "Investigación de antecedentes",
                  "Informe detallado",
                  "1 semana de vigilancia",
                ]}
              />
              <PricingCard
                title="Estándar"
                price="€1000"
                features={[
                  "Investigación de antecedentes",
                  "Informe detallado",
                  "1 mes de vigilancia",
                  "Análisis forense digital",
                ]}
              />
              <PricingCard
                title="Premium"
                price="€2000"
                features={[
                  "Investigación de antecedentes",
                  "Informe detallado",
                  "3 meses de vigilancia",
                  "Análisis forense digital",
                  "Consultoría personalizada",
                ]}
              />
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mt-8 md:mt-0">
              <h3 className="text-2xl font-bold text-center mb-4">Para Empresas</h3>
              <PricingCard
                title="Empresarial Básico"
                price="€1500"
                features={[
                  "Investigación de antecedentes de empleados",
                  "Informe detallado",
                  "2 semanas de vigilancia",
                ]}
              />
              <PricingCard
                title="Empresarial Estándar"
                price="€3000"
                features={[
                  "Investigación de antecedentes de empleados",
                  "Informe detallado",
                  "2 meses de vigilancia",
                  "Análisis forense digital",
                ]}
              />
              <PricingCard
                title="Empresarial Premium"
                price="€5000"
                features={[
                  "Investigación de antecedentes de empleados",
                  "Informe detallado",
                  "6 meses de vigilancia",
                  "Análisis forense digital",
                  "Consultoría personalizada",
                ]}
              />
            </div>
          </div>
        </section>

        {/* Sección Adicional 1: Testimonios */}
        <section className="mb-12">
          <h2 className="text-3xl font-bold text-center mb-6">Testimonios</h2>
          <div className="flex flex-wrap justify-around gap-6">
            <div className="max-w-md rounded-lg overflow-hidden shadow-lg bg-white p-6">
              <p className="text-gray-700 mb-4">"El servicio fue excepcional. La investigación fue exhaustiva y el informe muy detallado. Recomendado al 100%."</p>
              <p className="font-bold">- Cliente Satisfecho</p>
            </div>
            <div className="max-w-md rounded-lg overflow-hidden shadow-lg bg-white p-6">
              <p className="text-gray-700 mb-4">"Muy profesionales y confiables. El equipo siempre estuvo disponible para resolver nuestras dudas."</p>
              <p className="font-bold">- Empresa Cliente</p>
            </div>
          </div>
        </section>

        {/* Sección Adicional 2: Preguntas Frecuentes */}
        <section className="mb-12">
          <h2 className="text-3xl font-bold text-center mb-6">Preguntas Frecuentes</h2>
          <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-lg">
            <div className="space-y-4">
              <div>
                <h3 className="text-xl font-bold">¿Qué servicios incluyen los paquetes?</h3>
                <p className="text-gray-700">Nuestros paquetes incluyen una variedad de servicios dependiendo del nivel contratado. Consulta los detalles en cada tarjeta de precios.</p>
              </div>
              <div>
                <h3 className="text-xl font-bold">¿Cómo puedo contactar con ustedes?</h3>
                <p className="text-gray-700">Puedes contactarnos a través de WhatsApp haciendo clic en el botón de cada tarjeta de precios o enviándonos un correo electrónico.</p>
              </div>
              <div>
                <h3 className="text-xl font-bold">¿Cuánto tiempo tarda en recibir un informe?</h3>
                <p className="text-gray-700">El tiempo de entrega varía según la complejidad del caso y el paquete seleccionado. Por lo general, los informes se entregan en un plazo de 1 a 4 semanas.</p>
              </div>
            </div>
          </div>
        </section>
        
        {/* Sección Adicional 3: Contacto */}
        <section className="mb-12">
          <h2 className="text-3xl font-bold text-center mb-6">Contacto</h2>
          <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-lg">
            <p className="text-gray-700 mb-4">Para más información o para contratar nuestros servicios, no dudes en contactarnos a través de los siguientes medios:</p>
            <ul className="list-disc list-inside space-y-2">
              <li className="text-gray-700">
                <strong>Teléfono:</strong> +34 654 918 257
              </li>
              <li className="text-gray-700">
                <strong>Email:</strong> contacto@maytelas.com
              </li>
              <li className="text-gray-700">
                <strong>WhatsApp:</strong> <a href="https://wa.me/654918257" className="text-blue-500 hover:underline">Haz clic aquí para iniciar un chat</a>
              </li>
            </ul>
          </div>
        </section>

      </main>

      {modalOpen && <Modal onClose={handleModalClose} />}
    </div>
  );
}

export default Prices;
