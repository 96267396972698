import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Servicios from './pages/Servicios';
import Contacto from './pages/ContactPage';
import DonationSurvey from './components/DonationSurvey'
import FaqPage from './pages/FaqPage';
import DonationsPage from './pages/DonationsPage';
import Navbar from './components/Navbar';
import Home from './Home/Home';
import Acerca from './pages/Acerca';
import Prices from './pages/Prices';
import InfoPage from './Home/InfoPage';

const App = () => {
  const [currentPage, setCurrentPage] = useState('home');

  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route
            path="/Home"
            element={<Home setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/FaqPage"
            element={<FaqPage setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/Servicios"
            element={<Servicios setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/Contacto"
            element={<Contacto setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/DonationSurvey"
            element={<DonationSurvey setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/DonationsPage"
            element={<DonationsPage setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/Acerca"
            element={<Acerca setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/Navbar"
            element={<Navbar setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/Prices"
            element={<Prices setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/InfoPage"
            element={<InfoPage setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/"
            element={
              <Home
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
            }
          />
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
};
export default App;
