import React, { useState } from 'react';
import { FaEye, FaShieldAlt, FaCommentDots } from 'react-icons/fa';

const ServicesPage = () => {
  const [selectedService, setSelectedService] = useState(null);

  const services = [
    {
      title: 'Investigación de Infidelidades',
      description: 'Recolección de pruebas discretas y confidenciales para casos de sospecha de infidelidad.',
      details: 'Nuestros investigadores utilizan técnicas avanzadas para recolectar pruebas fotográficas y de video, análisis de dispositivos y seguimiento de actividades. Cada caso es manejado con total discreción y profesionalismo.',
      icon: <FaEye className="text-blue-600 text-4xl" />,
    },
    {
      title: 'Seguimientos y Vigilancias',
      description: 'Monitoreo profesional de personas y actividades para obtener información relevante.',
      details: 'Realizamos seguimientos exhaustivos con el uso de tecnología avanzada para garantizar resultados precisos. Nuestros equipos están capacitados para realizar vigilancias discretas y efectivas.',
      icon: <FaShieldAlt className="text-green-600 text-4xl" />,
    },
    {
      title: 'Análisis de Comunicación',
      description: 'Revisión de comunicaciones digitales para descubrir evidencias de conductas sospechosas.',
      details: 'Ofrecemos un análisis completo de comunicaciones digitales, con recuperación de datos eliminados y evaluación detallada de correos electrónicos, mensajes y llamadas.',
      icon: <FaCommentDots className="text-purple-600 text-4xl" />,
    },
    // Agrega más servicios si es necesario
  ];

  const testimonials = [
    {
      text: 'Gracias a su trabajo pude confirmar mis sospechas y tomar decisiones informadas sobre mi relación. Muy profesionales y discretos.',
      author: 'Lorena',
    },
    {
      text: 'El equipo me ayudó a obtener las pruebas que necesitaba para mi caso. Excelente servicio, lo recomiendo altamente.',
      author: 'Rodri',
    },
    {
      text: 'Muy agradecido por el trato recibido. Me sentí apoyado durante todo el proceso y recibí la información que buscaba.',
      author: 'Lorenzo ',
    },
    // Agrega más testimonios si es necesario
  ];

  return (
    <div className="bg-gray-50">
      {/* Hero Section */}
      <header className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-16 text-center">
        <h1 className="text-5xl font-extrabold">Nuestros Servicios</h1>
        <p className="mt-4 text-xl">Expertos en investigaciones privadas y soluciones discretas.</p>
      </header>

      {/* Services Section */}
      <main className="container mx-auto px-4 py-16">
        <section>
          <h2 className="text-4xl font-bold mb-12 text-center">Servicios Destacados</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <div
                key={index}
                className={`p-6 bg-white rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl cursor-pointer`}
                onClick={() => setSelectedService(index)}
                tabIndex={0}
                aria-expanded={selectedService === index}
                aria-controls={`service-details-${index}`}
              >
                <div className="flex items-center justify-center mb-4">
                  {service.icon}
                </div>
                <h3 className="text-2xl font-semibold mb-2">{service.title}</h3>
                <p>{service.description}</p>
                <button
                  className="mt-4 text-blue-600 hover:text-blue-800 font-medium"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedService(index);
                  }}
                  aria-expanded={selectedService === index}
                  aria-controls={`service-details-${index}`}
                >
                  Ver más
                </button>
              </div>
            ))}
          </div>
        </section>

        {selectedService !== null && (
          <section className="bg-gray-100 py-16 mt-12">
            <div className="container mx-auto px-4">
              <h3 id={`service-details-${selectedService}`} className="text-3xl font-bold mb-6 text-center">
                {services[selectedService].title}
              </h3>
              <p className="text-lg text-center max-w-3xl mx-auto">{services[selectedService].details}</p>
              <div className="text-center mt-8">
                <button
                  className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded transition-colors duration-300"
                  onClick={() => setSelectedService(null)}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </section>
        )}

        {/* FAQs Section */}
        <section className="container mx-auto px-4 py-16">
          <h2 className="text-4xl font-bold mb-12 text-center">Preguntas Frecuentes</h2>
          <div className="space-y-8">
            <div className="p-6 bg-white rounded-lg shadow-lg">
              <h4 className="font-semibold text-xl">¿Cómo garantizan la discreción en sus servicios?</h4>
              <p>Nuestro equipo opera con el más alto nivel de profesionalismo y confidencialidad, utilizando técnicas seguras y discretas para proteger la privacidad de nuestros clientes.</p>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-lg">
              <h4 className="font-semibold text-xl">¿Qué tipo de informes reciben los clientes?</h4>
              <p>Proporcionamos informes detallados que incluyen pruebas fotográficas y de video, análisis de datos y un resumen de nuestras observaciones.</p>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-lg">
              <h4 className="font-semibold text-xl">¿Cuánto tiempo suelen durar las investigaciones?</h4>
              <p>La duración varía según la complejidad del caso, pero siempre trabajamos para proporcionar resultados en el menor tiempo posible sin comprometer la calidad de la investigación.</p>
            </div>
            {/* Agrega más preguntas frecuentes según sea necesario */}
          </div>
        </section>

        {/* Pricing Section */}
        <section className="container mx-auto px-4 py-16">
          <h2 className="text-4xl font-bold mb-12 text-center">Precios de Nuestros Servicios</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="p-6 bg-white rounded-lg shadow-lg text-center border border-gray-200">
              <h3 className="text-2xl font-semibold mb-2">Investigación de Infidelidades</h3>
              <p className="text-xl font-bold mb-4">$500 - $1500</p>
              <p>Dependiendo de la duración y complejidad del caso. Ofrecemos paquetes personalizados según las necesidades específicas.</p>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-lg text-center border border-gray-200">
              <h3 className="text-2xl font-semibold mb-2">Seguimientos y Vigilancias</h3>
              <p className="text-xl font-bold mb-4">$300 - $1200</p>
              <p>Tarifa basada en horas y recursos utilizados. Se pueden incluir extras dependiendo de las condiciones del seguimiento.</p>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-lg text-center border border-gray-200">
              <h3 className="text-2xl font-semibold mb-2">Análisis de Comunicación</h3>
              <p className="text-xl font-bold mb-4">$400 - $1000</p>
              <p>Incluye recuperación y análisis de datos digitales. El precio puede variar según la cantidad de datos a revisar.</p>
            </div>
            {/* Agrega más precios según sea necesario */}
          </div>
        </section>

        {/* Testimonials Section */}
        <section className="container mx-auto px-4 py-16">
          <h2 className="text-4xl font-bold mb-12 text-center">Testimonios</h2>
          <div className="space-y-8">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="p-6 bg-white rounded-lg shadow-lg border-l-4 border-blue-500">
                <p className="italic text-lg">"{testimonial.text}"</p>
                <h4 className="mt-4 text-xl font-semibold">{testimonial.author}</h4>
              </div>
            ))}
          </div>
        </section>
      </main>
    </div>
  );
};

export default ServicesPage;
