import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  // Función para alternar la visibilidad del menú desplegable
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-gray-800 py-4">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center">
        <Link to="/" className="text-white text-3xl font-bold">
          MAYTELAS.com
        </Link>
        {/* Menú de escritorio (visible en pantallas md y mayores) */}
        <nav className="hidden md:flex md:space-x-6">
          <Link to="/" className="text-white hover:text-blue-300 transition-colors duration-300">
            Inicio
          </Link>
          <Link to="/acerca" className="text-white hover:text-blue-300 transition-colors duration-300">
            Acerca de Nosotros
          </Link>
          <Link to="/servicios" className="text-white hover:text-blue-300 transition-colors duration-300">
            Servicios
          </Link>
          <Link to="/contacto" className="text-white hover:text-blue-300 transition-colors duration-300">
            Contacto
          </Link>
          <Link to="/Prices" className="text-white hover:text-blue-300 transition-colors duration-300">
            Precios
          </Link>
        </nav>

        {/* Botón del menú móvil */}
        <div className="md:hidden">
          <button
            onClick={toggleMenu}
            className="text-white focus:outline-none"
          >
            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              {isOpen ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
              )}
            </svg>
          </button>
        </div>

        {/* Menú desplegable móvil */}
        {isOpen && (
          <div className="md:hidden absolute top-16 right-0 bg-gray-800 w-full py-2 z-20">
            <div className="flex flex-col items-center">
              <Link to="/" className="text-white hover:text-blue-300 py-2">
                Inicio
              </Link>
              <Link to="/acerca" className="text-white hover:text-blue-300 py-2">
                Acerca de Nosotros
              </Link>
              <Link to="/servicios" className="text-white hover:text-blue-300 py-2">
                Servicios
              </Link>
              <Link to="/contacto" className="text-white hover:text-blue-300 py-2">
                Contacto
              </Link>
              <Link to="/Prices" className="text-white hover:text-blue-300 py-2">
                Precios
              </Link>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;
