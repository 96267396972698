// src/components/Testimonials.jsx
import React from 'react';
import { motion } from 'framer-motion';

const testimonials = [
  { id: 1, name: 'Juan Pérez', feedback: 'Gracias a las donaciones, mi hijo ahora puede ir a la escuela.' },
  { id: 2, name: 'María Gómez', feedback: 'La ayuda recibida nos permitió tener acceso a agua potable.' },
  { id: 3, name: 'Carlos Ruiz', feedback: 'Gracias a los donantes, nuestra comunidad tiene acceso a atención médica.' },
];

const Testimonials = () => {
  return (
    <section className="py-20 bg-gray-200">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">Testimonios</h2>
        <div className="flex flex-wrap justify-around">
          {testimonials.map((testimonial) => (
            <motion.div
              key={testimonial.id}
              className="w-full md:w-1/3 p-4 text-center bg-white shadow-md rounded-lg mb-8"
              whileHover={{ scale: 1.05 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <p className="mb-4 italic">"{testimonial.feedback}"</p>
              <p className="font-bold">- {testimonial.name}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
