// src/pages/DonationsPage.jsx
import React from 'react';
import DonationsList from '../components/DonationsList';
import Benefits from '../components/Benefits';
import Testimonials from '../components/Testimonials';

const DonationsPage = () => {
  return (
    <div>
      <section className="py-20 bg-blue-600 text-white">
        <div className="max-w-6xl mx-auto px-4 text-center">
          <h1 className="text-4xl font-bold mb-4">Apoya Nuestros Proyectos</h1>
          <p className="mb-8">Tu contribución puede marcar la diferencia en la vida de muchas personas. Selecciona una causa y realiza tu donativo.</p>
        </div>
      </section>
      <DonationsList />
      <Benefits />
      <Testimonials />
    </div>
  );
}

export default DonationsPage;
