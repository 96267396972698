import React from 'react';
import { Link } from 'react-router-dom';

const Blog = () => {
  return (
    <section id="blog" className="py-12 bg-gray-100 text-gray-800">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8 text-center">Blog</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
            <h3 className="text-xl font-semibold mb-4">Cómo Detectar Señales de Infidelidad</h3>
            <p>Descubre los indicadores más comunes que podrían sugerir que tu pareja está siendo infiel...</p>
            <Link to="/InfoPage" className="text-blue-600 hover:underline">
              Leer más
            </Link>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
            <h3 className="text-xl font-semibold mb-4">Casos de Estudio: Lecciones Aprendidas</h3>
            <p>Revisamos algunos casos de infidelidad y lo que aprendimos de ellos para ayudar a futuros clientes...</p>
            <Link to="/InfoPage" className="text-blue-600 hover:underline">
              Leer más
            </Link>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
            <h3 className="text-xl font-semibold mb-4">Consejos para Mantener una Relación Saludable</h3>
            <p>Consejos y prácticas recomendadas para fortalecer la confianza y la comunicación en la relación...</p>
            <Link to="/InfoPage" className="text-blue-600 hover:underline">
              Leer más
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;
