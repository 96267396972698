import React from 'react';
import Services from '../Home/Services';
import Team from '../Home/Team';
import DynamicTestimonials from '../Home/DynamicTestimonials';
import Blog from '../Home/Blog';
import BannerHome from './BannerHome';

const Home = () => {
  return (
    <div className="space-y-12">
      <BannerHome />
      <Services />
      <Team />
      <DynamicTestimonials />
      <Blog />
    </div>
  );
};

export default Home;
