import React, { useState, useEffect } from 'react';

const testimonials = [
  {
    text: "Gracias a su trabajo pude confirmar mis sospechas y tomar decisiones informadas sobre mi relación. Muy profesionales y discretos.",
    author: "Alicia"
  },
  {
    text: "El equipo me ayudó a obtener las pruebas que necesitaba para mi caso. Excelente servicio, lo recomiendo altamente.",
    author: "Lorena"
  },
  {
    text: "Muy agradecido por el trato recibido. Me sentí apoyado durante todo el proceso y recibí la información que buscaba.",
    author: "Andres"
  }
];

const DynamicTestimonials = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
    }, 5000); // Cambia cada 5 segundos

    return () => clearInterval(interval);
  }, []);

  return (
    <section id="testimonials" className="py-12 bg-white text-gray-800">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8 text-center">Testimonios</h2>
        <div className="text-center max-w-xl mx-auto">
          <p className="italic">"{testimonials[currentTestimonial].text}"</p>
          <h4 className="mt-4 text-lg font-semibold">- {testimonials[currentTestimonial].author}</h4>
        </div>
      </div>
    </section>
  );
};

export default DynamicTestimonials;
