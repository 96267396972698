// src/pages/FaqPage.jsx
import React from 'react';

const faqs = [
  { question: '¿Cómo puedo realizar una donación?', answer: 'Puedes realizar una donación seleccionando una causa en nuestra sección de donaciones y siguiendo las instrucciones.' },
  { question: '¿A dónde va mi dinero?', answer: 'Tu dinero va directamente a los proyectos y causas que seleccionas. Nos aseguramos de que cada centavo sea utilizado de la mejor manera posible.' },
  { question: '¿Puedo donar de manera anónima?', answer: 'Sí, puedes elegir la opción de donar de manera anónima al realizar tu donación.' },
];

const FaqPage = () => {
  return (
    <div>
      <section className="py-20 bg-white">
        <div className="max-w-6xl mx-auto px-4">
          <h1 className="text-4xl font-bold mb-4 text-center">Preguntas Frecuentes</h1>
          <div className="flex flex-wrap justify-around">
            {faqs.map((faq, index) => (
              <div key={index} className="w-full md:w-1/2 lg:w-1/3 p-4 text-left bg-gray-100 shadow-md rounded-lg mb-8">
                <h2 className="text-xl font-semibold mb-2">{faq.question}</h2>
                <p>{faq.answer}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default FaqPage;
