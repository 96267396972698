// src/components/Benefits.jsx
import React from 'react';
import { FaHandsHelping, FaHeartbeat, FaSchool } from 'react-icons/fa';
import { motion } from 'framer-motion';

const benefits = [
  { icon: <FaHandsHelping />, title: 'Ayuda Directa', description: 'Tu donación llega directamente a quienes más lo necesitan.' },
  { icon: <FaHeartbeat />, title: 'Salva Vidas', description: 'Contribuyes a salvar vidas y mejorar la salud de comunidades enteras.' },
  { icon: <FaSchool />, title: 'Educación', description: 'Apoyas la educación de niños y jóvenes en comunidades desfavorecidas.' },
];

const Benefits = () => {
  return (
    <section className="py-20 bg-white">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">Beneficios de Donar</h2>
        <div className="flex flex-wrap justify-around">
          {benefits.map((benefit, index) => (
            <motion.div
              key={index}
              className="w-full md:w-1/3 p-4 text-center"
              whileHover={{ scale: 1.05 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <div className="text-4xl text-blue-600 mb-4">{benefit.icon}</div>
              <h3 className="text-2xl font-semibold mb-2">{benefit.title}</h3>
              <p>{benefit.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Benefits;
